jQuery(document).ready(function ($) {
  $('.img-wrapper .fade-in-1').fadeIn(500);
  $('.img-wrapper .fade-in-2').delay(3000).fadeIn(500);

  /* MOBILE MENU */
  $("body.mobile").delegate('.active-menu-back', 'click', function (e) {
    e.preventDefault();

    $(this).removeClass('active-menu-back');
    $(this).parent('li').find('> ul').hide(500);

  }).delegate('.active-menu-back-2', 'click', function (e) {
    e.preventDefault();

    $(this).removeClass('active-menu-back-2');
    $('.active-menu-back').show();
    $(this).parent('li').find('> ul').hide(300);

  }).delegate('.sub-menu > li > a:not(.active-menu-back-2)', 'click', function () {

    $(".menu-links").find('.active-menu-back').hide();
    $(this).addClass('active-menu-back-2');

    $sub_menu_li = $(this).parent('li').find("ul.sub-menu-2");
    if ($sub_menu_li.length) {
      $(this).parent('li').find("ul.sub-menu-2").show(300);
    }
  }).delegate('.menu-links .parent > a:not(.active-menu-back)', 'click', function (e) {
    e.preventDefault();

    $(".menu-links").find('.active-menu-back').removeClass('active-menu-back');
    $(".menu-links").find('.active-menu-back-2').removeClass('active-menu-back-2');
    $(this).addClass('active-menu-back');
    $(".menu-links ul.sub-menu").hide(500);
    $(this).parent('li').find("ul.sub-menu").show(500);
  });

  $(".desktop-menu-icon").on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $('body').removeClass('active');
      $('#desktop-header-background').removeAttr("style").hide();
    } else {
      $(this).addClass('active');
      $('body').addClass('active');
      var screen_height = $(window).height();
      $('#desktop-header-background').css('height', screen_height).show();
    }
  });

  $("#hamburger-icon").on('click', function () {
    if ($(this).hasClass('active')) {
      $('body').removeClass('active');
      $(".menu-links").find('.active-menu-back').show();
      $(".menu-links").find('.active-menu-back').removeClass('active-menu-back');
      $(".menu-links").find('.active-menu-back-2').removeClass('active-menu-back-2');
      $('#header-background').removeAttr("style").hide();

      $(".menu-links").hide(500);
      $(".mobile-language-select").hide();
      $(".sub-menu").hide();
      $(".logo").show();
      $(".mobile-slogan").show();
    } else {
      $('body').addClass('active');
      var screen_height = $(window).height();
      $(".menu-links").find('.active-menu-back').removeClass('active-menu-back');
      $(".menu-links").find('.active-menu-back-2').removeClass('active-menu-back-2');
      $('#header-background').css('height', screen_height).show();
      $('.sub-menu').css('height', screen_height);

      $(".menu-links").show(500);
      $(".mobile-language-select").show();
      $(".mobile-menu-back").show();
      $(".logo").hide();
      $(".mobile-slogan").hide();
    }
  });

  /* DESKTOP MENU */
  $(".desktop .menu-links .parent")
    .mouseover(function () {
      $(this).find("> a").addClass('active');
      $(this).find("ul.sub-menu").show();

      var screen_height = $(window).height();
      $('#header-background').css('height', screen_height).show();

    }).mouseout(function () {
      $(this).find("> a").removeClass('active');
      $(this).find("ul.sub-menu").hide();

      $('#header-background').removeAttr("style").hide();
    });
  $(".desktop .sub-menu > li")
    .mouseover(function () {
      $(this).find("> a").addClass('active');
      $sub_menu_li = $(this).find("ul.sub-menu-2");
      if ($sub_menu_li.length) {
        $(this).parent('ul').css('padding-right', '198px');
        $(this).find("ul.sub-menu-2").show();
      }

    }).mouseout(function () {
      $(this).find("> a").removeClass('active');
      $(this).find("ul.sub-menu-2").hide();
      $(this).parent('ul').removeAttr("style");
    });

  $('.benefit-item-wrapper').hover(function () {
    var benefit_image = $(this).attr('data-image');
    if (benefit_image !== undefined) {
      $('.benefit-photo img').attr('src', benefit_image);
    }
  });

  $('.interface-thumb').hover(function () {
    let interface_item_image = $(this).attr('src');
    let interface_item_id = $(this).attr('data-id');
    if (interface_item_image !== undefined) {
      $('#interface-img img').attr('src', interface_item_image);
      $('#interface-img source').attr('srcset', interface_item_image);
      $('.item-wrapper').hide();
      $('.item-wrapper.item-id-' + interface_item_id).show();
      $('.interface-thumb').removeClass('active');
      $(this).addClass('active');
    }
  });

  var benefit_image_top = $('.product-grid--benefits-3__column').outerHeight() + 50;
  // $('.benefit-photo img').css('top', benefit_image_top + 'px');

  $('.benefit-popup').click(function (e) {
    var popupId = $(this).data('id');
    $('#popup-id-' + popupId).lightbox_me({
      centered: true,
      overlayCSS: { background: 'black', opacity: .6 }
    });
    e.preventDefault();
  });

  $('a[href="#ask-question"]').click(function (e) {
    e.preventDefault();
    $('#ask-question').lightbox_me({
      centered: true,
      overlayCSS: { background: 'black', opacity: 1 }
    });
  });

  $('.info-button').click(function (e) {
    popupId = $(this).attr('data-popup_id');
    if (popupId == 'plug-and-play-video-popup') {
      let videoUrl = $(this).data("video-url")
      $('#plug-and-play-video-popup-wrapper').html('<iframe id="video-player" width="500" height="280" allowfullscreen frameborder="0" class="embed-responsive-item" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" src="' + videoUrl + '"></iframe>');
    }
    $('#' + popupId).lightbox_me({
      centered: true,
      overlayCSS: { background: 'black', opacity: .6 },
      onClose: function () { $('#plug-and-play-video-popup-wrapper').html('') }
    });
    e.preventDefault();
  });

  $('#feature-video-button').click(function (e) {
    let videoUrl = $(this).data("video-url")
    $('#feature-video-popup-wrapper').html('<iframe id="video-player" width="500" height="280" allowfullscreen frameborder="0" class="embed-responsive-item" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" src="' + videoUrl + '"></iframe>');
    $('#feature-video-popup').lightbox_me({
      centered: true,
      overlayCSS: { background: 'black', opacity: .6 },
      onClose: function () { $('#feature-video-popup-wrapper').html('') }
    });
    e.preventDefault();
  });

  $('.show-video').click(function (e) {
    let videoUrl = $(this).data("video-url")
    $('#video-popup-wrapper').html('<iframe id="video-player" width="500" height="280" allowfullscreen frameborder="0" class="embed-responsive-item" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" src="' + videoUrl + '"></iframe>');
    $('#videos-video-popup').lightbox_me({
      centered: true,
      overlayCSS: { background: 'black', opacity: .6 },
      onClose: function () { $('#video-popup-wrapper').html('') }
    });
    e.preventDefault();
  });
});

; (function ($, window, document, undefined) {
  'use strict';

  var elSelector = '#header',
    $element = $(elSelector);

  if (!$element.length) return true;

  var elHeight = 0,
    elTop = 0,
    $document = $(document),
    dHeight = 0,
    $window = $(window),
    wHeight = 0,
    wScrollCurrent = 0,
    wScrollBefore = 0,
    wScrollDiff = 0;

  $window.on('scroll', function () {
    elHeight = $element.outerHeight();
    dHeight = $document.height();
    wHeight = $window.height();
    wScrollCurrent = $window.scrollTop();
    wScrollDiff = wScrollBefore - wScrollCurrent;
    elTop = parseInt($element.css('top')) + wScrollDiff;

    if (wScrollCurrent <= 0) {
      $element.css('top', 0);
      $element.removeClass('nav-down', 400);
      // scrolled to the very top; element sticks to the top
    } else if (wScrollDiff > 0) {
      $element.addClass('nav-down');
      // scrolled up; element slides in
      $element.css('top', elTop > 0 ? 0 : elTop);
    }
    else if (wScrollDiff < 0) // scrolled down
    {
      if (wScrollCurrent + wHeight >= dHeight - elHeight) {
        $element.addClass('nav-down');
        // scrolled to the very bottom; element slides in
        $element.css('top', (elTop = wScrollCurrent + wHeight - dHeight) < 0 ? elTop : 0);
      } else {
        // scrolled down; element slides out
        $element.css('top', Math.abs(elTop) > elHeight ? -elHeight : elTop);
      }
    }

    wScrollBefore = wScrollCurrent;
  });

})(jQuery, window, document);

/* Distinction between mobile and desktop menu */
$(window).resize(function () {
  if ($(window).width() > 1279) {
    $('body').removeClass('mobile');
    $('body').addClass('desktop');
  } else {
    $('body').removeClass('desktop');
    $('body').addClass('mobile');
  }
});
if ($(window).width() > 1279) {
  $('body').removeClass('mobile');
  $('body').addClass('desktop');
} else {
  $('body').removeClass('desktop');
  $('body').addClass('mobile');
}

$('.collapsible .item-title').on('click', function () {
  let faq_coll = $(this).parent('.collapsible');
  if (faq_coll.hasClass('active')) {
    faq_coll.removeClass('active');
  } else {
    faq_coll.addClass('active');
  }
});

/* MOBILE HAMBURGER ICON ACTION */
$(document).ready(function () {
  var hamburger = $('#hamburger-icon');
  hamburger.click(function () {
    hamburger.toggleClass('active');
    return false;
  });
});

jQuery('.product-grid--benefits-3').each(function () {
  jQuery(this).children('.product-grid--benefits-3__column:not(.done)').matchHeight({
    byRow: true,
    property: 'height'
  }).addClass('done');
});

jQuery('.col-equals').each(function () {
  jQuery(this).children('.col-equal-height:not(.done)').matchHeight({
    byRow: true,
    property: 'height'
  }).addClass('done');
});

$(document).ready(function () {
  $(".animsition").animsition({
    inClass: 'fade-in',
    outClass: 'fade-out',
    inDuration: 1500,
    outDuration: 800,
    linkElement: '.animsition-link',
    // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
    loading: true,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: ['animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay: false,
    overlayClass: 'animsition-overlay-slide',
    overlayParentElement: 'body',
    transition: function (url) { window.location.href = url; }
  });

  // Checkout page sticky
  if ($('.sticky-wrapper').length) {
    $elStickyWrapper = $('.sticky-wrapper');
    $elStickyPosition = $elStickyWrapper.offset();
    $(window).on('scroll', function () {
      $bottom = $elStickyPosition.top + 445;
      var scrollBottom = $(window).scrollTop() + $(window).height();
      if (scrollBottom > $bottom) {
        $elStickyWrapper.addClass('fixed');
      } else {
        $elStickyWrapper.removeClass('fixed');
      }
    });
  }

  $('#orderform .btn-orderform-choice-many-car').click(function (e) {
    e.preventDefault();
    $('#orderform .form-section').show();
    $('.orderform-choice').hide();
  })
});
